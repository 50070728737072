import React, { Component } from 'react'
import { Card, Typography } from 'antd'
const { Text } = Typography;
export default class Blog extends Component {
  render() {
    return (
      <Card>
        <Text>This is Blog</Text>
      </Card>
    )
  }
}

import React, { Component } from 'react'
import { Card, Typography } from 'antd'
const { Text } = Typography;

export default class SubjectMatrix extends Component {
  render() {
    return (
      <Card>
        <Text> This is SubjectMatrix </Text>
      </Card>
    )
  }
}

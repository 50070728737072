import React, { Component } from 'react'
import { Card, Typography } from 'antd'
const { Text } = Typography;

export default class QuestLog extends Component {
  render() {
    return (
      <Card>
        This is QuestLog
      </Card>
    )
  }
}

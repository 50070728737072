import React, { Component } from 'react'

export default class Working extends Component {
  render() {
    return (
      <div style={{display:'flex',alignItems: 'center',flexDirection: 'column',justifyContent: 'center',marginTop: '100px'}}>
        <img style={{height: '220px'}}src="https://media.istockphoto.com/vectors/cute-girl-smiling-sketch-for-your-design-vector-id480376205?k=6&m=480376205&s=612x612&w=0&h=OkucnvdECajUMxZzARsfoPHy92r_sJWMrM4rnktTUR0=" />
         <h1 style={{textAlign:'center'}}>Hooorayyyy !!!!! I have a visitor</h1>
         <h2 style={{textAlign:'center'}}>Thank you for checking, but I am still dusting the switches and wiring up few ports</h2>
         <h4 style={{textAlign:'center'}}>I will let you know when I am done, pinky promise :)</h4>
      </div>
    )
  }
}
